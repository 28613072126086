import { Button, Col, DatePicker, Divider, Input, Pagination, Row, Select, Space, Switch, message } from "antd";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { createAxiosInstance } from "../../api/ApiRequest";
import { NavLink, useNavigate } from "react-router-dom";
import { formatWallet } from "../../utils/utils";
import { SaveOutlined, SendOutlined } from '@ant-design/icons';

const LetterDraw = ({ }) => {
    const [draws, setDraws] = useState([]);
    const [fStart, setFStart] = useState('');
    const [fEnd, setFEnd] = useState('');
    const [filterStatus, setFilterStatus ] = useState('claimed');
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalDraws, setTotalDraws] = useState(0);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const RangePicker = DatePicker.RangePicker;
    const { Option } = Select;

    useEffect(() => {
        getDraws(filterStatus, fStart, fEnd, pageNum, pageSize);
    }, []);

    const onChangePayout = (drawIndex, ev) => {
        let _draws = draws;
        _draws[drawIndex].payout = ev.target.value;
        setDraws(_draws);
    }

    const onChangeStatus = (drawIndex, val) => {
        let _draws = draws;
        _draws[drawIndex].status = val;
        setDraws(_draws);
    }

    const onChangeFilterStatus = (val) => {
        setFilterStatus(val);
        getDraws(val, fStart, fEnd, pageNum, pageSize, false);
    }

    const handleSubmitPayout = (drawIndex) => {
        console.log(drawIndex, draws[drawIndex]);
        const _draw = draws[drawIndex];
        let _draws = draws;
        axiosInstance.post(`/api/v1/dashboard/letter/payout`, { drawId: _draw._id, payout: _draw.payout, status: _draw.status })
            .then((res) => {
                if (res.data.draw) {
                    _draws[drawIndex] = res.data.draw;
                    setDraws(_draws);
                    // notifySuccess('Payout has been updated successfully.');
                    message.success('Successfully!');
                } else {
                    // notifyError(res.data.msg);
                    message.error(res.data.msg);
                }
            })
    }

    const copyWallet = (wallet) => {
        if (!wallet) return;
        navigator.clipboard.writeText(wallet);
        message.success('Copied to clipboard.');
    }

    const onDateRangeChange = (value, dateString) => {
        console.log(dateString);
        let _fStart = new Date(dateString[0]).getTime();
        let _fEnd = new Date(dateString[1]).getTime();
        setFStart(_fStart);
        setFEnd(_fEnd);
        setPageNum(1);
        getDraws(filterStatus, _fStart, _fEnd, 1, pageSize);
    };

    const getDraws = (status, startAt, endAt, pageNum, pageSize, shouldUpdatePagination = true) => {
        axiosInstance.post(`/api/v1/dashboard/letter/nft-cards`, { status, startAt, endAt, pageNum, pageSize })
            .then((res) => {
                setDraws(res.data.draws);
                if (shouldUpdatePagination) {
                    setTotalDraws(res.data.total);
                }
            })
    }

    const onChangePage = (_pageNum, _pageSize) => {
        setPageNum(_pageNum);
        setPageSize(_pageSize);
        getDraws(filterStatus, fStart, fEnd, _pageNum, _pageSize, false);
    }


    return (
        <WithAuth>
            <MainWrapper>
                <Space>
                    <RangePicker
                        format="YYYY-MM-DD"
                        onChange={onDateRangeChange}
                    />
                    <Select defaultValue={'claimed'} onChange={(val) => onChangeFilterStatus(val)}>
                        <Option value="claimed">Claimed</Option>
                        <Option value="pending">Unclaimed</Option>
                    </Select>
                </Space>
                <Divider />
                <Row className="p-2">
                    <Col xl={1} md={1} xs={6}><strong>No</strong></Col>
                    <Col xl={3} md={3} xs={6}><strong>Card</strong></Col>
                    <Col xl={3} md={3} xs={6}><strong>Telegram</strong></Col>
                    <Col xl={3} md={3} xs={6}><strong>Wallet</strong></Col>
                    <Col xl={6} md={6} xs={6}><strong>Payout</strong></Col>
                    <Col xl={3} md={5} xs={6}><strong>Time Stamp</strong></Col>
                    <Col xl={5} md={3} xs={6}><strong>Status</strong></Col>
                </Row>
                {
                    draws?.map((draw, key) => (
                        <Row key={key} className="p-2">
                            <Col xl={1} md={1} xs={6}>
                                <NavLink to={'/dashboard/number-draws/' + draw._id} className={'text-black'} style={{ wordWrap: 'break-word' }}> {key + 1 + pageNum * pageSize - pageSize}</NavLink>
                            </Col>
                            <Col xl={3} md={3} xs={6}>{draw.name}</Col>
                            <Col xl={3} md={3} xs={6} style={{ padding: '0px 5px' }}>{draw.telegram}</Col>
                            <Col xl={3} md={3} xs={6} style={{ padding: '0px 5px' }} onClick={() => copyWallet(draw.userWallet)}>{formatWallet(draw.userWallet)}</Col>
                            <Col xl={6} md={6} xs={6}>
                                <Row>
                                    <Col xl={22}>
                                        <Input defaultValue={draw.payout} onChange={(ev) => onChangePayout(key, ev)} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={3} md={3} xs={6}>{dayjs(new Date(parseInt(draw.requestAt))).format('YYYY-MM-DD HH:mm:ss')}</Col>
                            <Col xl={5} md={5} xs={6} className="text-capitalize">
                                <Row>
                                    <Col xl={8}>
                                        <Select defaultValue={draw.status} onChange={(val) => onChangeStatus(key, val)}>
                                            <Option value={'claimed'}>Claimed</Option>
                                            <Option value={'pending'}>Unclaimed</Option>
                                        </Select>
                                    </Col>
                                    <Col xl={8}>
                                        <Button type="primary" className="p-2" onClick={() => handleSubmitPayout(key)}><SaveOutlined /></Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ))
                }
                {
                    totalDraws
                        ? <Pagination showQuickJumper current={pageNum} pageSize={pageSize} total={totalDraws} onChange={onChangePage} />
                        : <></>
                }
            </MainWrapper>
        </WithAuth>
    )
}


export default LetterDraw;