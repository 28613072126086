import { Button, Col, Divider, Form, Input, message, Row, Space, Switch } from "antd";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import { useNavigate } from "react-router-dom";
import { createAxiosInstance } from "../../api/ApiRequest";
import { useEffect, useState } from "react";
import { SaveOutlined } from "@ant-design/icons";

const LetterGameSetting = () => {
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);
    const [series, setSeries] = useState([]);
    const [gameSetting, setGameSetting] = useState({});
    const [form] = Form.useForm();

    const handleStatusUpdate = (s, val) => {
        axiosInstance.post(`/api/v1/dashboard/letter/series`, {
            status: val,
            name: s.name
        }).then((res) => {
            getSeries();
        });
    };

    const getSeries = () => {
        axiosInstance.get(`/api/v1/dashboard/game_settings?category=letter`)
            .then((res) => {
                if (res.data) {
                    setSeries(res.data.series);
                    setGameSetting(res.data.setting);
                    form.setFieldsValue({
                        nftQty: res.data.setting?.nftQty,
                        status: res.data.setting?.status === 'enabled'
                    });
                }
            });
    };

    const onFinish = (values) => {
        axiosInstance.post(`/api/v1/dashboard/letter/setting`, values)
        .then((res) => {
            if (res.data.msg) {
                message.error(res.data.msg);
            }
            if (res.data.letter) {
                message.success('Successfully updated');
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        getSeries();
    }, []);

    return (
        <WithAuth>
            <MainWrapper>
                <Space><h6>Letter Game Setting</h6></Space>

                <Form
                    form={form}
                    name="letter"
                    layout="inline"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Win NFT Qty"
                        type="number"
                        name="nftQty"
                        rules={[
                            {
                                required: true,
                                message: 'Please input win nft qty!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Enable" name="status" valuePropName="checked">
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Form.Item>
                </Form>

                <Divider />
                <Space><h6>Series</h6></Space>
                <Row>
                    <Col md={8} xs={24}>
                        {
                            series.map((s, key) => (
                                <Row gutter={20} key={key} style={{ paddingTop: 5, paddingBlock: 5, background: key % 2 == 1 ? 'initial' : '#eee' }}>
                                    <Col span={20}>{s.name}</Col>
                                    <Col span={4}><Switch checked={s.status === 'enabled'} onChange={(val) => handleStatusUpdate(s, val)} /></Col>
                                </Row>
                            ))
                        }
                    </Col>
                </Row>
            </MainWrapper>
        </WithAuth>
    );
};

export default LetterGameSetting;
