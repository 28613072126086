import { Button, Col, DatePicker, Divider, Input, Pagination, Row, Space, message } from "antd";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { createAxiosInstance } from "../../api/ApiRequest";
import { NavLink, useNavigate } from "react-router-dom";
import { formatWallet } from "../../utils/utils";
import { SendOutlined } from '@ant-design/icons';
import { notifyError, notifySuccess } from "../../utils/toast";

const BuybackDraw = ({}) => {
    const [ draws, setDraws] = useState([]);
    const [ fStart, setFStart ] = useState('');
    const [ fEnd, setFEnd ] = useState('');
    const [ pageNum, setPageNum ] = useState(1);
    const [ pageSize, setPageSize ] = useState(10);
    const [ totalDraws, setTotalDraws ] = useState(0);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const RangePicker = DatePicker.RangePicker;

    useEffect(() => {
        getDraws(fStart, fEnd, pageNum, pageSize);
    }, []);

    const onChangePayout = (drawIndex, ev) => {
        let _draws = draws;
        _draws[drawIndex].payout = ev.target.value;
        setDraws(_draws);
    }

    const onChangeSeller = (drawIndex, ev) => {
        let _draws = draws;
        _draws[drawIndex].sellerWallet = ev.target.value;
        setDraws(_draws);
    }

    const onChangeSellerHash = (drawIndex, ev) => {
        let _draws = draws;
        _draws[drawIndex].sellerTransaction = ev.target.value;
        setDraws(_draws);
    }

    const handleSubmitPayout = (drawIndex) => {
        console.log(drawIndex, draws[drawIndex]);
        const _draw = draws[drawIndex];
        let _draws = draws;
        axiosInstance.post(`/api/v1/dashboard/buyback/payout`, { drawId: _draw._id, payout: _draw.payout, sellerWallet: _draw.sellerWallet, sellerTransaction: _draw.sellerTransaction })
            .then((res) => {
                if (res.data.draw) {
                    _draws[drawIndex] = res.data.draw;
                    setDraws(_draws);
                    // notifySuccess('Payout has been updated successfully.');
                    message.success('Successfully!');
                } else {
                    // notifyError(res.data.msg);
                    message.error(res.data.msg);
                }
            })
    }

    const copyWallet = (wallet) => {
        if (!wallet) return;
        navigator.clipboard.writeText(wallet);
        notifySuccess('Copied to clipboard.');
    }

    const onDateRangeChange = (value, dateString) => {
        console.log(dateString);
        let _fStart = new Date(dateString[0]).getTime();
        let _fEnd = new Date(dateString[1]).getTime();
        setFStart(_fStart);
        setFEnd(_fEnd);
        setPageNum(1);
        getDraws(_fStart, _fEnd, 1, pageSize);
    };

    const getDraws = (startAt, endAt, pageNum, pageSize, shouldUpdatePagination = true) => {
        axiosInstance.post(`/api/v1/dashboard/buyback/draws`, { startAt, endAt, pageNum, pageSize})
        .then((res) => {
            setDraws(res.data.draws);
            if (shouldUpdatePagination) {
                setTotalDraws(res.data.total);
            }
        })
    }

    const onChangePage = (_pageNum, _pageSize) => {
        setPageNum(_pageNum);
        setPageSize(_pageSize);
        getDraws(fStart, fEnd, _pageNum, _pageSize, false);
    }


    return (
        <>
            <Space>
                <RangePicker
                    format="YYYY-MM-DD"
                    onChange={onDateRangeChange}
                />
            </Space>
            <Divider/>
            <Row className="p-2">
                <Col xl={1} md={6} xs={6}><strong>No</strong></Col>
                <Col xl={3} md={6} xs={6}><strong>NFT</strong></Col>
                <Col xl={2} md={6} xs={6}><strong>Payout Price</strong></Col>
                <Col xl={3} md={6} xs={6}><strong>Start</strong></Col>
                <Col xl={3} md={6} xs={6}><strong>End</strong></Col>
                <Col xl={2} md={6} xs={6}><strong>Expire</strong></Col>
                <Col xl={3} md={6} xs={6}><strong>Seller</strong></Col>
                <Col xl={3} md={6} xs={6}><strong>Seller Hash</strong></Col>
                <Col xl={4} md={6} xs={6}><strong>Payout</strong></Col>
            </Row>
            {
                draws?.map((draw, key) => (
                    <Row key={key} className="p-2">
                        <Col xl={1} md={6} xs={6}>
                            <NavLink to={'/dashboard/number-draws/' + draw._id} className={'text-black'} style={{wordWrap: 'break-word'}}> {key + 1 + pageNum * pageSize - pageSize}</NavLink>
                        </Col>
                        <Col xl={3} md={6} xs={6}>{draw.win_nft}</Col>
                        <Col xl={2} md={6} xs={6}>{draw.price.toFixed(4)}</Col>
                        <Col xl={3} md={6} xs={6}>{dayjs(new Date(parseInt(draw.startAt))).format('YYYY-MM-DD HH:mm:ss')}</Col>
                        <Col xl={3} md={6} xs={6}>{dayjs(new Date(parseInt(draw.endAt))).format('YYYY-MM-DD HH:mm:ss')}</Col>
                        <Col xl={2} md={6} xs={6}>{dayjs(new Date(parseInt(draw.expiredAt))).format('YYYY-MM-DD HH:mm:ss')}</Col>
                        <Col xl={3} md={6} xs={6} style={{padding: '0px 5px'}}>
                            <Input defaultValue={draw.sellerWallet} onChange={(ev) => onChangeSeller(key, ev)} />
                        </Col>
                        <Col xl={3} md={6} xs={6} style={{padding: '0px 5px'}}>
                            <Input defaultValue={draw.sellerTransaction} onChange={(ev) => onChangeSellerHash(key, ev)} />
                        </Col>
                        <Col xl={4} md={6} xs={6}>
                            <Row>
                                <Col xl={20}><Input defaultValue={draw.payout} onChange={(ev) => onChangePayout(key, ev)} /></Col>
                                <Col xl={4}><Button type="text" className="p-2" onClick={() => handleSubmitPayout(key)}><SendOutlined /></Button></Col>
                            </Row>
                        </Col>
                    </Row>
                ))
            }
            {
                totalDraws 
                ? <Pagination showQuickJumper current={pageNum} pageSize={pageSize} total={totalDraws} onChange={onChangePage} />
                : <></>
            }
        </>
    )
}

const BuybackDraws = () => {
    useEffect(() => {
    }, [])

    return (
        <WithAuth>
            <MainWrapper>
                <BuybackDraw />
            </MainWrapper>
        </WithAuth>
    )
}

export default BuybackDraws;