import { Button, Col, DatePicker, Divider, Input, Pagination, Row, Space, message } from "antd";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { createAxiosInstance } from "../../api/ApiRequest";
import { NavLink, useNavigate } from "react-router-dom";
import { formatWallet } from "../../utils/utils";
import { SendOutlined } from '@ant-design/icons';
import { notifyError, notifySuccess } from "../../utils/toast";

const Redeems = ({ }) => {
    const [redeems, setRedeems] = useState([]);
    const [fStart, setFStart] = useState('');
    const [fEnd, setFEnd] = useState('');
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRedeems, setTotalRedeems] = useState(0);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const RangePicker = DatePicker.RangePicker;

    useEffect(() => {
        getRedeems(fStart, fEnd, pageNum, pageSize);
    }, []);

    const onChangePayout = (drawIndex, ev) => {
        let _draws = redeems;
        _draws[drawIndex].payout = ev.target.value;
        setRedeems(_draws);
    }

    const handleSubmitPayout = (drawIndex) => {
        console.log(drawIndex, redeems[drawIndex]);
        const _draw = redeems[drawIndex];
        let _draws = redeems;
        axiosInstance.post(`/api/v1/dashboard/redeem/payout`, {payout: _draw.payout, id: _draw._id})
            .then((res) => {
                if (res.data.redeem) {
                    _draws[drawIndex] = res.data.redeem;
                    setRedeems(_draws);
                    // notifySuccess('Payout has been updated successfully.');
                    message.success('Successfully!');
                } else {
                    // notifyError(res.data.msg);
                    message.error(res.data.msg);
                }
            })
    }

    const copyWallet = (wallet) => {
        if (!wallet) return;
        navigator.clipboard.writeText(wallet);
        notifySuccess('Copied to clipboard.');
    }

    const onDateRangeChange = (value, dateString) => {
        console.log(dateString);
        let _fStart = new Date(dateString[0]).getTime();
        let _fEnd = new Date(dateString[1]).getTime();
        setFStart(_fStart);
        setFEnd(_fEnd);
        setPageNum(1);
        getRedeems(_fStart, _fEnd, 1, pageSize);
    };

    const getRedeems = (startAt, endAt, pageNum, pageSize, shouldUpdatePagination = true) => {
        axiosInstance.post(`/api/v1/dashboard/redeems`, { startAt, endAt, pageNum, pageSize })
            .then((res) => {
                setRedeems(res.data.redeems);
                if (shouldUpdatePagination) {
                    setTotalRedeems(res.data.total);
                }
            })
    }

    const onChangePage = (_pageNum, _pageSize) => {
        setPageNum(_pageNum);
        setPageSize(_pageSize);
        getRedeems(fStart, fEnd, _pageNum, _pageSize, false);
    }


    return (
        <WithAuth>
            <MainWrapper>
                <Space>
                    <RangePicker
                        format="YYYY-MM-DD"
                        onChange={onDateRangeChange}
                    />
                </Space>
                <Divider />
                <Row className="p-2">
                    <Col xl={1} md={6} xs={6}><strong>No</strong></Col>
                    <Col xl={3} md={6} xs={6}><strong>Wallet</strong></Col>
                    <Col xl={3} md={6} xs={6}><strong>Pts</strong></Col>
                    <Col xl={3} md={6} xs={6}><strong>Request At</strong></Col>
                    <Col xl={3} md={6} xs={6}><strong>Payout At</strong></Col>
                    <Col xl={4} md={6} xs={6}><strong>Payout</strong></Col>
                </Row>
                {
                    redeems?.map((draw, key) => (
                        <Row key={key} className="p-2">
                            <Col xl={1} md={6} xs={6}>
                                {key + 1 + pageNum * pageSize - pageSize}
                            </Col>
                            <Col xl={3} md={6} xs={6}>{formatWallet(draw.walletAddress)}</Col>
                            <Col xl={3} md={6} xs={6}>{draw.pts}</Col>
                            <Col xl={3} md={6} xs={6}>{dayjs(new Date(parseInt(draw.createdAt))).format('YYYY-MM-DD HH:mm:ss')}</Col>
                            <Col xl={3} md={6} xs={6}>{draw.payoutAt ? dayjs(new Date(parseInt(draw.payoutAt))).format('YYYY-MM-DD HH:mm:ss') : ''}</Col>
                            <Col xl={4} md={6} xs={6}>
                                <Row>
                                    <Col xl={20}><Input defaultValue={draw.payout} onChange={(ev) => onChangePayout(key, ev)} /></Col>
                                    <Col xl={4}><Button type="text" className="p-2" onClick={() => handleSubmitPayout(key)}><SendOutlined /></Button></Col>
                                </Row>
                            </Col>
                        </Row>
                    ))
                }
                {
                    totalRedeems
                        ? <Pagination showQuickJumper current={pageNum} pageSize={pageSize} total={totalRedeems} onChange={onChangePage} />
                        : <></>
                }
            </MainWrapper>
        </WithAuth>
    )
}

export default Redeems;