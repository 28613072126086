import { Button, Col, Divider, Form, Input, Modal, Row, Space } from "antd";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { createAxiosInstance } from "../../api/ApiRequest";
import { notifyError, notifySuccess } from "../../utils/toast";
import { SaveOutlined } from "@ant-design/icons";


const TextTranslate = () => {
    const [searchParam] = useSearchParams();
    const code = searchParam.get('code');
    const page_code = searchParam.get('page')
    const [trans, setTrans] = useState([]);
    const [langs, setLangs] = useState([]);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    useEffect(() => {
        getTexts(code, page_code);
        getLangs();
    }, [code, page_code]);

    const getLangs = async () => {
        let res = await axiosInstance.get('/api/v1/translate/langs');
        if (res.data.langs) {
            setLangs(res.data.langs);
        }
    }

    const getTexts = async (_code, _pcode) => {
        let res = await axiosInstance.get(`/api/v1/translate/text/trans?code=${_code}&page_code=${_pcode}`);
        if (res.data.trans) {
            setTrans(res.data.trans);
        }
    }

    const onFinish = async (values) => {
        let key = Object.keys(values)[0];
        let lang_code = key.split('_')[1];
        let val = values[key];
        let res = await axiosInstance.post('/api/v1/translate/text/trans', {
            page_code: page_code,
            code: code,
            lang_code: lang_code,
            content: val
        });
        if (res.data.tran) {
            notifySuccess('Succeed!');
        }
        if (res.data.msg) {
            notifyError(res.data.msg);
        }
    }

    const getInitialContent = (lang) => {
        let _trans = trans.filter((t)=>{
            return t.lang_code==lang
        });
        return _trans[0]?.content
    }

    return (
        <WithAuth>
            <MainWrapper>
                <Space><h6>Translations for {'{{' + page_code + '.' + code + '}}'}</h6></Space>
                <Divider />
                {
                    langs.map((lang, key) => (
                        <Form key={key} name={"trans_" + lang.code} layout="horizontal" wrapperCol={{ span: 24 }} onFinish={onFinish} autoComplete="off">
                            <Row className="align-items-center">
                                <Col span={20}>
                                    <Form.Item
                                        label={lang.label.toUpperCase()}
                                        name={'content_' + lang.code}
                                        wrapperCol={{ span: 20 }}
                                        labelCol={{ span: 4 }}
                                        initialValue={trans.filter((t)=>t.lang_code == lang.code)?.[0]?.content}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item wrapperCol={{ span: 20, offset: 4 }}>
                                        <Button type="primary" htmlType="submit">
                                            <SaveOutlined />
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    ))
                }
            </MainWrapper>
        </WithAuth>
    )
};

export default TextTranslate;