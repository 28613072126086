import { Button, Col, DatePicker, Divider, Input, Pagination, Row, Space, Tabs } from "antd";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { createAxiosInstance } from "../../api/ApiRequest";
import { NavLink, useNavigate } from "react-router-dom";
import { formatWallet } from "../../utils/utils";
import { SendOutlined } from '@ant-design/icons';
import { notifyError, notifySuccess } from "../../utils/toast";

const CoinDraws = ({ game }) => {
    const [gameCoin, setGameCoin] = useState(game.currency);
    const [draws, setDraws] = useState([]);
    const [fStart, setFStart] = useState('');
    const [fEnd, setFEnd] = useState('');
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalDraws, setTotalDraws] = useState(0);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const RangePicker = DatePicker.RangePicker;

    useEffect(() => {
        setGameCoin(game.currency);
        getDraws(game.currency, fStart, fEnd, pageNum, pageSize);
    }, [game]);

    const onChangePayout = (drawIndex, ev) => {
        let _draws = draws;
        _draws[drawIndex].prize.payout = ev.target.value;
        setDraws(_draws);
    }

    const handleSubmitPayout = (drawIndex) => {
        console.log(drawIndex, draws[drawIndex]);
        const _draw = draws[drawIndex];
        let _draws = draws;
        axiosInstance.post(`/api/v1/dashboard/payout`, { drawId: _draw._id, payout: _draw.prize.payout })
            .then((res) => {
                if (res.data.prize) {
                    _draws[drawIndex].prize = res.data.prize;
                    setDraws(_draws);
                    notifySuccess('Payout has been updated successfully.');
                } else {
                    notifyError(res.data.msg);
                }
            })
    }

    const copyWallet = (wallet) => {
        if (!wallet) return;
        navigator.clipboard.writeText(wallet);
        notifySuccess('Copied to clipboard.');
    }

    const copyPrize = (qty) => {
        if (!qty) return;
        navigator.clipboard.writeText(qty);
        notifySuccess('Copied to clipboard.');
    }

    const onDateRangeChange = (value, dateString) => {
        console.log(dateString);
        let _fStart = new Date(dateString[0]).getTime();
        let _fEnd = new Date(dateString[1]).getTime();
        setFStart(_fStart);
        setFEnd(_fEnd);
        setPageNum(1);
        getDraws(gameCoin, _fStart, _fEnd, 1, pageSize);
    };

    const getDraws = (coin, startAt, endAt, pageNum, pageSize, shouldUpdatePagination = true) => {
        axiosInstance.post(`/api/v1/dashboard/draws`, { coin, startAt, endAt, pageNum, pageSize })
            .then((res) => {
                setDraws(res.data.draws);
                if (shouldUpdatePagination) {
                    setTotalDraws(res.data.total);
                }
            })
    }

    const onChangePage = (_pageNum, _pageSize) => {
        setPageNum(_pageNum);
        setPageSize(_pageSize);
        getDraws(gameCoin, fStart, fEnd, _pageNum, _pageSize, false);
    }


    return (
        <>
            <Space>
                <RangePicker
                    format="YYYY-MM-DD"
                    onChange={onDateRangeChange}
                />
            </Space>
            <Divider />
            <Row className="p-2">
                <Col xl={2} md={6} xs={8}><strong>No</strong></Col>
                <Col xl={2} md={6} xs={8}><strong>Coin</strong></Col>
                <Col xl={2} md={6} xs={8}><strong>Price</strong></Col>
                <Col xl={3} md={6} xs={8}><strong>Start</strong></Col>
                <Col xl={3} md={6} xs={8}><strong>End</strong></Col>
                <Col xl={2} md={6} xs={8}><strong>Prize</strong></Col>
                <Col xl={3} md={6} xs={8}><strong>Winner</strong></Col>
                {
                    game.currency !== 'pts'
                        ?
                        <>
                            <Col xl={2} md={6} xs={8}><strong>Margin</strong></Col>
                            <Col xl={4} md={6} xs={8}><strong>Payout</strong></Col>
                        </>
                        :
                        <></>
                }
            </Row>
            {
                draws?.map((draw, key) => (
                    <Row key={key} className="p-2">
                        <Col xl={2} md={6} xs={8}>
                            <NavLink to={'/dashboard/number-draws/' + draw._id} className={'text-black'} style={{ wordWrap: 'break-word' }}> {key + 1 + pageNum * pageSize - pageSize}</NavLink>
                        </Col>
                        <Col xl={2} md={6} xs={8}>{draw.currency.toUpperCase()}</Col>
                        <Col xl={2} md={6} xs={8}>{draw.price.toFixed(4)}</Col>
                        <Col xl={3} md={6} xs={8}>{dayjs(new Date(parseInt(draw.startAt))).format('YYYY-MM-DD HH:mm:ss')}</Col>
                        <Col xl={3} md={6} xs={8}>{dayjs(new Date(parseInt(draw.endAt))).format('YYYY-MM-DD HH:mm:ss')}</Col>
                        <Col xl={2} md={6} xs={8}><span onClick={() => copyPrize(draw.prize?.qty)}>{draw.prize?.qty.toFixed(4)}</span></Col>
                        <Col xl={3} md={6} xs={8}><span onClick={() => copyWallet(draw.prize?.winnerWallet)}>{formatWallet(draw.prize?.winnerWallet)}</span></Col>
                        {
                            game.currency !== 'pts'
                                ?
                                <>
                                    <Col xl={2} md={6} xs={8}>{draw.adminProfit?.qty.toFixed(4)}</Col>
                                    <Col xl={4} md={6} xs={8}>

                                        <Row>
                                            <Col xl={20}><Input defaultValue={draw.prize?.payout} disabled={!draw.prize} onChange={(ev) => onChangePayout(key, ev)} /></Col>
                                            <Col xl={4}><Button disabled={!draw.prize} type="text" className="p-2" onClick={() => handleSubmitPayout(key)}><SendOutlined /></Button></Col>
                                        </Row>

                                    </Col>
                                </>
                                :
                                <></>
                        }
                    </Row>
                ))
            }
            {
                totalDraws
                    ? <Pagination showQuickJumper current={pageNum} pageSize={pageSize} total={totalDraws} onChange={onChangePage} />
                    : <></>
            }
        </>
    )
}

const NumberDraws = () => {
    const [gameSettings, setGameSettings] = useState([]);
    const [tabItems, setTabItems] = useState([]);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    useEffect(() => {
        axiosInstance.get(`/api/v1/dashboard/game_settings?category=number`)
            .then((res) => {
                if (res?.data) {
                    let _settings = res.data.settings
                    setGameSettings(_settings);
                    let _tabItems = [];
                    for (let i = 0; i < _settings.length; i++) {
                        _tabItems.push({
                            key: `${i}`,
                            label: _settings[i].currency.toUpperCase(),
                            children: <CoinDraws game={_settings[i]} />
                        });
                    }
                    setTabItems(_tabItems);
                }
            });
    }, [])

    return (
        <WithAuth>
            <MainWrapper>
                <Tabs defaultActiveKey="1" items={tabItems} />
            </MainWrapper>
        </WithAuth>
    )
}

export default NumberDraws;