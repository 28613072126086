import { Button, Form, Input } from "antd";

const AddLangModal = ({onSubmit}) => {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('Success:', values);
        onSubmit(values);
        form.resetFields();
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                remember: false,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Language Code"
                name="code"
                rules={[
                    {
                        required: true,
                        message: 'Please input language short code!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Name"
                name="label"
                rules={[
                    {
                        required: true,
                        message: 'Please input language name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    offset: 20,
                    span: 4,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default AddLangModal;