import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import MainWrapper from '../layout/MainWrapper';
import { createAxiosInstance } from '../api/ApiRequest';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const onFinish = async (values) => {
        const res = await axiosInstance.post(`/api/v1/admin/forgot-password`, {
            ...values
        });
        if (res.data.message) {
            message.success(res.data.message);
        } else {
            message.error(res.data.err);
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <MainWrapper>
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                    margin: 'auto'
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Email"
                    name="email"
                    type="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Email!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Reset Password
                    </Button>
                </Form.Item>
            </Form>
        </MainWrapper>
    )
}

export default ForgotPassword;