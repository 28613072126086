import { Button, Col, Divider, Form, Input, Row, Switch, Tabs } from 'antd';
import { WithAuth } from "../../components/with-auth"
import MainWrapper from "../../layout/MainWrapper"
import { useEffect, useState } from 'react';
import { createAxiosInstance } from '../../api/ApiRequest';
import { notifyError, notifySuccess } from '../../utils/toast';
import { useNavigate } from 'react-router-dom';

const BuybackForm = ({setting}) => {
    const [ mySetting, setMySetting ] = useState(setting);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const onFinish = (values) => {
        axiosInstance.post(`/api/v1/dashboard/buyback_setting`, values)
        .then((res) => {
            if (res.data.msg) {
                notifyError(res.data.msg);
            }
            if (res.data.buyback) {
                notifySuccess('Successfully updated');
            }
        })
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (setting) {
            setMySetting({...setting});
        }
    }, [setting]);

    return (
        <>
        {
            mySetting &&
            <Form
            name="buyback"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label={"Period (min)"}
                type="number"
                name="period"
                min={1}
                initialValue={mySetting.period / 60000}
                rules={[
                    {
                        required: true,
                        message: 'Please input period!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Expire Time (min)"
                type="number"
                name="expireTime"
                min={1}
                initialValue={mySetting.expireTime / 60000}
                rules={[
                    {
                        required: true,
                        message: 'Please input expire!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Break (min)"
                type="number"
                step="1"
                min={1}
                name="breakTime"
                initialValue={mySetting.breakTime / 60000}
                rules={[
                    {
                        required: true,
                        message: 'Please input break!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Buy back price (USDT)"
                type="number"
                name="price"
                initialValue={mySetting.price}
                rules={[
                    {
                        required: true,
                        message: 'Please input buy-back price!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item label="Enable" name="status" valuePropName="checked" initialValue={mySetting.status == 'enabled'}>
                <Switch />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </Form.Item>
        </Form>
        }
        </>
        
    )
}

const BuybackGameSetting = () => {
    const [ buybackSetting, setBuybackSetting] = useState(null);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const getSetting = async () => {
        axiosInstance.get(`/api/v1/dashboard/game_settings?category=buyback`)
        .then((res) => {
            if (res.data.buyback) {
                setBuybackSetting(res.data.buyback);
            }
        })
    }

    useEffect(()=>{
        getSetting();
    }, [])

    return (
        <WithAuth>
            <MainWrapper>
                <Row gutter={20}>
                    <Col xs={24} md={12}>
                        <BuybackForm setting={buybackSetting} />
                    </Col>
                </Row>
            </MainWrapper>
        </WithAuth>
    )
}

export default BuybackGameSetting;