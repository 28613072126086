import { useEffect, useState } from "react";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import { createAxiosInstance } from "../../api/ApiRequest";
import { Button, Col, DatePicker, Divider, Input, List, Pagination, Row, Space } from "antd";
import { formatWallet } from "../../utils/utils";
import { NavLink, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";

const Users = () => {
    const [ searchWallet, setSearchWallet ] = useState('');
    const [users, setUsers] = useState([]);
    const [ pageNum, setPageNum ] = useState(1);
    const [ pageSize, setPageSize ] = useState(10);
    const [ totalUsers, setTotalUsers ] = useState(0);
    const [ totalRewards, setTotalRewards] = useState(0);
    const [ purchasedRewards, setPurchasedRewards ] = useState(0);
    const [ allRedeems, setAllRedeems ] = useState(0);
    const [ date, setDate ] = useState(0);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const getUsers = (_pageNum, _pageSize, _searchDate = 0, _walletAddrss = '') => {
        axiosInstance.post(`/api/v1/admin/users`, {pageNum: _pageNum, pageSize: _pageSize, wallet: _walletAddrss, searchDate: _searchDate})
        .then((res) => {
            console.log(res);
            setUsers(res.data.users);
            setTotalUsers(res.data.allUsers);
            setTotalRewards(res.data.totalRewards);
            setPurchasedRewards(res.data.purchasedRewards);
            setAllRedeems(res.data.allRedeems);
        })
    }

    useEffect(() => {
        getUsers(pageNum, pageSize)
    }, []);

    const onChangePage = (_pageNum, _pageSize) => {
        setPageNum(_pageNum);
        setPageSize(_pageSize);
        getUsers(_pageNum, _pageSize, date, searchWallet);
    }

    const onSearch = () => {
        getUsers(1, 10, date, searchWallet);
    }

    const onChangeDate = (date, dateString) => {
        if (date) {
            setDate(new Date(date.$d).getTime());
        } else {
            setDate(0);
        }
    }

    return (
        <WithAuth>
            <MainWrapper>
                <Row gutter={10}>
                    <Col md={4} xs={24}>
                        <Space>User wallet: <input className="search-input" type="search" value={searchWallet} onChange={(ev) => setSearchWallet(ev.target.value)} /></Space>
                    </Col>
                    <Col md={5} xs={24}>
                        <Space>Date: <DatePicker onChange={onChangeDate} />&nbsp;<Button onClick={onSearch}><SearchOutlined/></Button></Space>
                    </Col>
                    <Col md={5} xs={24} className="mt-1">
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid green', borderRadius: 10, padding: 5}}>
                            Total Reward Points: {parseFloat(totalRewards).toFixed(2)}
                        </div>
                    </Col>
                    <Col md={5} xs={24} className="mt-1">
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid green', borderRadius: 10, padding: 5}}>
                            Total Purchased Points: {purchasedRewards}
                        </div>
                    </Col>
                    <Col md={5} xs={24} className="mt-1">
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid green', borderRadius: 10, padding: 5}}>
                            Total NFT redeemed: {allRedeems}
                        </div>
                    </Col>
                </Row>
                
                <Divider/>
                <Row>
                    <Col md={1} xs={3}>No</Col>
                    <Col md={6} xs={9}>Wallet</Col>
                    <Col md={6} xs={3}>Pts</Col>
                    <Col md={6} xs={9}>Signed at</Col>
                </Row>
                {
                    users.map((user, key)=> (
                        <Row key={key} className="p-2">
                            <Col md={1} xs={3}>{key + 1 + pageNum * pageSize - pageSize}</Col>
                            <Col md={6} xs={9}><NavLink to={`/dashboard/users/${user.walletAddress}`}>{formatWallet(user.walletAddress)}</NavLink></Col>
                            <Col md={6} xs={3}>{user.totalRewards.toFixed(2)}</Col>
                            <Col md={6} xs={9}>{dayjs(new Date(parseInt(user.createdAt))).format('YYYY-MM-DD HH:mm')}</Col>
                        </Row>
                    ))
                }
                {
                totalUsers 
                ? <Pagination showQuickJumper current={pageNum} pageSize={pageSize} total={totalUsers} onChange={onChangePage} />
                : <></>
                }
            </MainWrapper>
        </WithAuth>
    )
}

export default Users;