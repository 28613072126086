import { NavLink, useNavigate } from "react-router-dom";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import { createAxiosInstance } from "../../api/ApiRequest";
import { useEffect, useState } from "react";
import { Button, Col, Divider, Modal, Popconfirm, Row, Space, message } from "antd";
import { notifyError } from "../../utils/toast";
import AddLangModal from "./partials/AddLangModal";
import AddPageModal from "./partials/AddPageModal";

const Website = () => {
    const [langs, setLangs] = useState([]);
    const [pages, setPages] = useState([]);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const [open, setOpen] = useState(false);
    const [pageOpen, setPageOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const navigate = useNavigate();

    const handleCancel = () => {
        setOpen(false);
    };

    const handlePageCancel = () => {
        setPageOpen(false);
    }

    const handleAddLang = () => {
        setOpen(true);
    }

    const submitLang = async (formData) => {
        let res = await axiosInstance.post('/api/v1/translate/lang', formData);
        if (res.data.lang) {
            setLangs([...langs, res.data.lang]);
        } else {
            notifyError(res.data.msg);
        }
        setOpen(false);
    }

    const submitPage = async (formData) => {
        let res = await axiosInstance.post('/api/v1/translate/page', formData);
        if (res.data.page) {
            setPages([...pages, res.data.page]);
        } else {
            notifyError(res.data.msg);
        }
        setPageOpen(false);
    }

    const deletePage = async (pcode, index) => {
        axiosInstance.delete('/api/v1/translate/page/' + pcode).then(res=>{
            if (res.data.deleted) {
                console.log(res.data.deleted, index);
                setPages((prevPages) => {
                    prevPages.splice(index, 1);
                    return prevPages
                });
                setPageCount(pages.length - 1);
                message.success('Deleted!');
            }
        });
    }

    useEffect(() => {
        (async () => {
            // get languages
            let _langs = await axiosInstance.get('/api/v1/translate/langs');
            if (_langs.data.langs) {
                setLangs(_langs.data.langs);
            }
            // get pages
            let _pages = await axiosInstance.get('/api/v1/translate/pages');
            if (_pages?.data?.pages) {
                setPages(_pages.data.pages);
            }
        })();
    }, []);

    const confirmDelete = (pcode, index)=> {
        deletePage(pcode, index);
    }

    const cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    };

    return (
        <WithAuth>
            <MainWrapper>
                <Space><h6>Supported Languages</h6></Space>
                <Row gutter={5}>
                    <Col>
                        <Button type="primary" onClick={handleAddLang}>Add</Button>
                    </Col>
                    {
                        langs.map((lang, key) => (
                            <Col key={key}>
                                <Button>{lang.label}</Button>
                            </Col>
                        ))
                    }
                </Row>
                <Divider />
                <Row>
                    <Col span={8}><h6>Pages Or Blocks <span className="d-none">{pageCount}</span></h6></Col>
                    <Col span={8} offset={8}>
                        <div className="d-flex justify-content-end">
                            <Button type="primary" onClick={()=>setPageOpen(true)}>Add Page</Button>
                        </div>
                    </Col>
                </Row>
                <Row gutter={5}>
                    <Col xl={2}><strong>No</strong></Col>
                    <Col xl={4}><strong>Page Code</strong></Col>
                    <Col xl={6}><strong>Page Name</strong></Col>
                    <Col xl={6}><strong>Actions</strong></Col>
                </Row>
                <>
                    {
                        pages.map((p, key) => (
                            <Row gutter={5} key={key} style={{paddingTop: 5, paddingBlock: 5, background: key%2==1 ? 'initial' : '#eee'}}>
                                <Col xl={2}>{key + 1}</Col>
                                <Col xl={4}>{p.code}</Col>
                                <Col xl={6}>{p.name}</Col>
                                <Col xl={6}>
                                <Popconfirm
                                    title="Delete item ?"
                                    description="Are you sure to delete this item?"
                                    onConfirm={()=>confirmDelete(p.code, key)}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary" danger ghost 
                                        // onClick={()=>deletePage(p.code, key)}
                                    >Delete</Button>
                                </Popconfirm> &nbsp;
                                    <Button type="primary" onClick={()=>navigate('/dashboard/website/page?code=' + p.code)}>View Text</Button>
                                </Col>
                            </Row>
                        ))
                    }
                </>
                <Modal
                    title="Title"
                    open={open}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <AddLangModal onSubmit={submitLang}/>
                </Modal>

                <Modal
                    title="Title"
                    open={pageOpen}
                    onCancel={handlePageCancel}
                    footer={null}
                >
                    <AddPageModal onSubmit={submitPage}/>
                </Modal>
            </MainWrapper>
        </WithAuth>
    )
}

export default Website;