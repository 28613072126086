import { Button, Col, Modal, Popconfirm, Row, Space, message } from "antd";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { createAxiosInstance } from "../../api/ApiRequest";
import AddTextModal from "./partials/AddTextModal";
import { notifyError } from "../../utils/toast";

const PageText = () => {
    const [searchParam] = useSearchParams();
    const code = searchParam.get('code');
    const [texts, setTexts] = useState([]);
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);
    const [textCount, setTextCount ] = useState(0);

    const [open, setOpen] = useState(false);

    useEffect(()=>{
        getTexts(code);
    }, [code]);

    const getTexts = async (code) => {
        let res = await axiosInstance.get('/api/v1/translate/texts?page_code=' + code);
        if (res.data.texts) {
            setTexts(res.data.texts);
        }
    }

    const handleCancel = () => {
        setOpen(false);
    };

    const deleteText = async (id, index) => {
        let res = await axiosInstance.delete('/api/v1/translate/text/' + id);
        if (res.data.deleted) {
            setTexts((prevTexts) => {
                prevTexts.splice(index, 1);
                return prevTexts
            });
            setTextCount(texts.length - 1);
            message.success('Deleted!');
        }
    }

    const confirmDelete = (id, index)=> {
        deleteText(id, index);
    }

    const cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    };

    const submitText = async (formData) => {
        let res = await axiosInstance.post('/api/v1/translate/text', {...formData, page_code: code, lang_code: 'en'});
        if (res.data.page_text) {
            setTexts([...texts, res.data.page_text]);
        } else {
            notifyError(res.data.msg);
        }
        setOpen(false);
    }

    return (
        <WithAuth>
            <MainWrapper>
                <Space><h6>Texts on {'{{' + code + '}}'}<span className="d-none">{textCount}</span></h6></Space>

                <Row>
                    <Col span={8} offset={16}>
                        <div className="d-flex justify-content-end">
                            <Button type="primary" onClick={()=>setOpen(true)}>Add Text</Button>
                        </div>
                    </Col>
                </Row>
                <Row gutter={5}>
                    <Col xl={2}><strong>No</strong></Col>
                    <Col xl={4}><strong>Text Code</strong></Col>
                    <Col xl={10}><strong>Content</strong></Col>
                    <Col xl={8}><strong>Actions</strong></Col>
                </Row>
                <>
                    {
                        texts.map((t, key) => (
                            <Row gutter={5} key={key} style={{paddingTop: 5, paddingBlock: 5, background: key%2==1 ? 'initial' : '#eee'}}>
                                <Col xl={2}>{key + 1}</Col>
                                <Col xl={4}>{t.code}</Col>
                                <Col xl={10}>{t.content}</Col>
                                <Col xl={8}>
                                <Popconfirm
                                    title="Delete item?"
                                    description="Are you sure to delete this item?"
                                    onConfirm={()=>confirmDelete(t._id, key)}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary" danger ghost>Delete</Button>
                                </Popconfirm>
                                    &nbsp;
                                    <Button type="primary" onClick={()=>history(`/dashboard/website/text?code=${t.code}&page=${t.page_code}`)}>Translations</Button>
                                </Col>
                            </Row>
                        ))
                    }
                </>

                <Modal
                    title="Title"
                    open={open}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <AddTextModal onSubmit={submitText}/>
                </Modal>
            </MainWrapper>
        </WithAuth>
    )
};

export default PageText;