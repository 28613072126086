import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Users from "../pages/dashboard/Users";
import Home from "../pages/Home";
import NumberDraws from "../pages/dashboard/NumberDraws";
import UserDetail from "../pages/dashboard/UserDetail";
import DrawDetail from "../pages/dashboard/DrawDetail";
import Profile from "../pages/dashboard/Profile";
import Website from "../pages/dashboard/Website";
import PageText from "../pages/dashboard/PageText";
import TextTranslate from "../pages/dashboard/TextTranslate";
import BuybackDraws from "../pages/dashboard/BuybackDraws";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import NumberGameSetting from "../pages/dashboard/NumberGameSetting";
import BuybackGameSetting from "../pages/dashboard/BuybackGameSetting";
import LetterGameSetting from "../pages/dashboard/LetterGameSetting";
import LetterDraw from "../pages/dashboard/LetterDraws";
import Redeems from "../pages/dashboard/Redeems";

const AppNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/dashboard/users" element={<Users />} />
        <Route path="/dashboard/users/:wallet" element={<UserDetail />} />
        <Route path="/dashboard/number-draws" element={<NumberDraws />} />
        <Route path="/dashboard/number-draws/:drawId" element={<DrawDetail />} />
        <Route path="/dashboard/buyback-draws" element={<BuybackDraws />} />
        <Route path="/dashboard/setting/number" element={<NumberGameSetting />} />
        <Route path="/dashboard/setting/buyback" element={<BuybackGameSetting />} />
        <Route path="/dashboard/setting/letter" element={<LetterGameSetting />} />
        <Route path="/dashboard/profile" element={<Profile />} />
        <Route path="/dashboard/website" element={<Website />} />
        <Route path="/dashboard/letters" element={<LetterDraw />} />
        <Route path="/dashboard/website/page" element={<PageText />} />
        <Route path="/dashboard/website/text" element={<TextTranslate />} />
        <Route path="/dashboard/redeems" element={<Redeems />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppNavigation;