import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { createAxiosInstance } from "../../api/ApiRequest";
import { Col, Divider, Row } from "antd";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import { formatWallet, tonScanUrl } from "../../utils/utils";
import { notifySuccess } from "../../utils/toast";
import dayjs from "dayjs";

const DrawDetail = () => {
    const { drawId } = useParams();
    const [ drawInfo, setDrawInfo ] = useState({});
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);

    const copyWallet = (wallet) => {
        if (!wallet) return;
        navigator.clipboard.writeText(wallet);
        notifySuccess('Copied to clipboard.');
    }
    
    useEffect(()=>{
        axiosInstance.get(`/api/v1/dashboard/draw?drawId=${drawId}`)
            .then((res)=>{
                setDrawInfo(res.data);
            })
    }, [drawId]);

    return (
        <WithAuth>
            <MainWrapper>
                {
                    drawInfo?.prize?.winnerWallet 
                    ?
                    <h6>Winner: &nbsp;
                        <a href={tonScanUrl(drawInfo?.prize?.winnerWallet)} target="_blank" >
                            {formatWallet(drawInfo?.prize?.winnerWallet)}
                        </a> 
                        &nbsp;Prize: &nbsp;{drawInfo?.prize?.qty.toFixed(4) + ' ' + drawInfo?.prize?.currency.toUpperCase() }
                    </h6>
                    :
                    <><h6>No Winner</h6></>
                }
                <Divider />
                <p>Numbers</p>
                <Row gutter={5}>
                    <Col md={2} xs={3}>No</Col>
                    <Col md={4} xs={6}>Wallet</Col>
                    <Col md={4} xs={6}>Number</Col>
                    <Col md={4} xs={6}>TimeStamp</Col>
                </Row>
                
                {
                    drawInfo?.numbers?.map((number, key) => (
                        <Row key={key} gutter={5} style={{backgroundColor: number.isWinner ? '#45f882' : 'initial', padding: '5px 0'}}>
                            <Col md={2} xs={3}>{key + 1}</Col>
                            <Col md={4} xs={6}><span onClick={() => copyWallet(number.walletAddress)}>{formatWallet(number.walletAddress)}</span></Col>
                            <Col md={4} xs={6}>{number.num}</Col>
                            <Col md={4} xs={6}>{dayjs(new Date(parseInt(number.createdAt))).format('YY/MM/DD HH:mm')}</Col>
                        </Row>
                    ))
                }
                
            </MainWrapper>
        </WithAuth>
    )
}

export default DrawDetail;